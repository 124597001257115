<template>
  <v-container fluid>
    <v-progress-circular
        indeterminate
        color="green"
        v-if="initialLoader"
        style="margin-left: 10px;"
    ></v-progress-circular>
    <h1>SuperAdmin</h1>
    <p>You really should not be here.</p>
    <v-row v-if="!initialLoader">
      <v-col>
        <v-btn class="mx-1 my-1" v-for="(route, i) of adminRoutes" :key="i" :to="route.path">{{route.name}}</v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import superAdminRoutes from "../../router/superAdmin";

export default {
  name: 'Home',
  data () {
    return {
      initialLoader: true,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      adminRoutes: [],
    }
  },
  computed: {
    ...mapGetters(['getUsername', 'getEndpoint'])
  },
  async mounted(){
    try { 
      this.adminRoutes = superAdminRoutes.filter(x=>x.name!=="superadmin");
    } catch (error) {
      console.error(error)
      this.snack(error.msg || error, "error");
    }finally {
      this.initialLoader = false;
    }
  },
  methods: {
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    formatDate(d, type="short"){
      if(!d) return "None";
      if(type=="short")
        return (new Date(d)).toLocaleDateString('en-GB')
      if(type=="long")
        return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
