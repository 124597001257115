<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="d-flex flex-column">
            <span class="d-flex flex-row align-center">
              <h1 class="mr-2">Return #{{this.$route.params.id}}</h1>
              <div class="mr-2">
                <span v-if="this.dreturn.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
                <span v-if="this.dreturn.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
                <span v-if="this.dreturn.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
                <span v-if="this.dreturn.status===2" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
                <span v-if="this.dreturn.status===3" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(this.dreturn.status)}}</span>
              </div>
              <v-icon v-if="this.updateStateValue===0" class="ml-2" color="warning">mdi-cloud-refresh</v-icon>
              <v-icon v-if="this.updateStateValue===1" class="ml-2" color="success">mdi-cloud-check</v-icon>
              <v-icon v-if="this.updateStateValue===2" class="ml-2" color="error">mdi-cloud-alert</v-icon>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="initialLoader"
              ></v-progress-circular>
              <v-btn :disabled="!returnOk()" @click="openSignDialog()" color="success" small class="ml-2">Seal</v-btn>
            </span>
            <span>Created By: {{this.lookupUsername(this.dreturn.createdBy)}}</span>
            <span>Created On: {{utils.formatDate(this.dreturn.createdAt)}} at {{utils.formatTime(this.dreturn.createdAt)}}</span>
          </span>
        </v-col>
        <v-col>
          <span class="mr-3 d-flex flex-row">
            <h3 style="text-align: left;">Customer</h3>
            <v-btn :disabled="this.modifyDisabledCriteria" v-if="!this.modifyDisabledCriteria" @click="openSearchCustomerDialog" style="margin-left: 10px;" fab color="info" x-small><v-icon>mdi-account-search</v-icon></v-btn>
          </span>
          <span v-if="this.dreturn.customerId && selectedCustomer" class="d-flex flex-row justify-start align-center">
            <span class="d-flex flex-column mr-2">
              <h2 style="text-align: left;">{{selectedCustomer.name}}</h2>
              <span v-if="selectedCustomer.phone"><b>Phone: </b>{{selectedCustomer.phone}}</span>
            </span>
            <v-btn v-if="this.selectedCustomer" @click="()=>this.$router.push({path:'/customers/view/'+this.selectedCustomer.id})" fab x-small color="info" class="mr-2"><v-icon>mdi-chevron-right</v-icon></v-btn>
            <v-btn :disabled="this.modifyDisabledCriteria" @click="removeCustomerFromReturn()" fab x-small color="error"><v-icon>mdi-close</v-icon></v-btn>
          </span>
          <span v-else>
            Select a customer, or scan an invoice.
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" v-if="!this.modifyDisabledCriteria">
          <v-card outlined class="mb-3">
            <v-card-title>
              <h3>Products in Order #{{this.$route.query.order|| "-"}}</h3>
            </v-card-title>
            <v-card-text>
              <span v-if="this.selectedOrder">
                <div v-for="item in this.selectedOrder.OrderLineItems" :key="item.id">
                  <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                    <span class="d-flex flex-row justify-space-between">
                      <h3 style="text-align: left;">{{item.productName}}</h3>
                      <v-btn x-small fab color="success" @click="addReturnLineItem(item)"><v-icon>mdi-plus</v-icon></v-btn>
                    </span>
                    <span class="d-flex flex-column align-start">
                      <span><b>Unit Price: ${{item.unitPrice}}</b></span>
                      <span><b>Quantity Purchased: {{item.quantity}}</b></span>
                      <!-- <p>{{item}}</p> -->
                      <span>ID: {{item.productId}}, SKU:{{item.sku}}</span>
                    </span>
                  </div>
                </div>
              </span>
            </v-card-text>
          </v-card>
          <v-card outlined>
            <v-card-title>
              <h3>Orders</h3>
              <v-data-table
                  :headers="purchaseTable.headers"
                  :items="purchaseTable.items"
                  :items-per-page="-1"
                  class="elevation-1"
                  @click:row="purchaseTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span>{{utils.formatDate(item.createdAt) }}</span>
                </template>
              </v-data-table>
            </v-card-title>
            <v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card outlined>
            <v-card-title class="d-flex flex-row justify-space-between">
              <h3>Returned Items ({{this.dreturn.ReturnLineItems?this.dreturn.ReturnLineItems.length:0}})</h3>
              <span class="d-flex flex-column">
                <h5>Subtotal: {{utils.formatCurrency(this.getSubTotal)|| 0}}</h5>
                <h4 class="d-flex flex-row">Total: <v-text-field :disabled="this.modifyDisabledCriteria" class="ml-2" dense outlined v-model="dreturn.totalValue" @change="updateReturn()"></v-text-field><v-btn :disabled="parseFloat(this.dreturn.totalValue) == parseFloat(this.getSubTotal)" fab x-small class="ml-2" color="info" @click="()=>{this.dreturn.totalValue = parseFloat(this.getSubTotal); this.updateReturn()}"><v-icon>mdi-check</v-icon></v-btn></h4>
              </span>
            </v-card-title>
            <v-card-text>
              <div v-for="item in this.dreturn.ReturnLineItems" :key="item.id">
                <div class="d-flex flex-column justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                  <span class="d-flex flex-row justify-space-between">
                    <h3 style="text-align: left;">{{item.Product.name}}</h3>
                    <span>Product ID: {{item.productId}}</span>
                    <span>Order ID: {{item.orderId}}</span>
                    <v-btn x-small :disabled="modifyDisabledCriteria" fab color="error" @click="removeReturnLineItem(item.id)"><v-icon>mdi-close</v-icon></v-btn>
                  </span>
                  <span class="d-flex flex-row align-center">
                    <span class="d-flex flex-row align-center justify-center">
                      <v-text-field :disabled="modifyDisabledCriteria" @change="updateReturnLineItem(item)" class="mr-2" dense outlined v-model="item.serial" label="Serial"></v-text-field>
                      <v-text-field :disabled="modifyDisabledCriteria" @change="updateReturnLineItem(item)" dense outlined v-model="item.returnValue" type="number" label="Value"></v-text-field>
                      <v-checkbox :disabled="modifyDisabledCriteria" @change="updateReturnLineItem(item)" v-model="item.isReplacement" label="Replacement ? "></v-checkbox>
                      <v-checkbox :disabled="modifyDisabledCriteria" @change="updateReturnLineItem(item)" v-model="item.addedToInventory" class="ml-5" label="Added back to inventory ?"></v-checkbox>
                    </span> 
                  </span>
                  <v-textarea :disabled="modifyDisabledCriteria" @change="updateReturnLineItem(item)" dense outlined label="Notes" v-model="item.notes"></v-textarea>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="searchCustomerDialog.isOpen" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Customers
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field v-model="searchCustomerDialog.term" @change="searchCustomer" :loading="searchCustomerDialog.loader" label="Search Customers" outlined></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-card outlined :key="i" v-for="(customer, i) in searchCustomerDialog.results" class="d-flex flex-row align-center mt-2">
            <span class="d-flex flex-column justify-space-between" style="text-align: left; padding: 20px;">
              <h3>{{customer.name}}</h3>
              <p v-if="customer.email">{{customer.email}}</p>
              <p v-if="customer.phone">{{customer.phone}}</p>
            </span>
            <v-btn @click="setCustomer(customer)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeSearchCustomerDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="signDialog.isOpen"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you wish to sign this Return Form?
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="signDialog.password"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeSignDialog()"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            :loading="this.signDialog.loading"
            :disabled="!this.signDialog.password"
            @click="attemptSign()"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style>
</style>
<script>
import axios from 'axios';
import {mapGetters, mapMutations} from 'vuex'
import utils from  '../../plugins/helpers'
import scanSystem from "../../plugins/scanSystem"
import _ from 'lodash';
export default {
  data () {
    return {
      utils: utils,
      loader: false,
      initialLoader: true,
      numUpdates: 0,
      updateStateValue: 1, // 0 = updating; 1 = updateSuccess; 2 = updateFail
      locationLoading: false,
      snackObj: {
        state: false,
        color: '',
        text: ''
      },
      singular: "Return",
      singularLower: "return",
      plural: "Returns",
      pluralLower: "returns",
      dreturn: {},
      searchCustomerDialog: {
        isOpen: false,
        term: '',
        results: [],
        loader: false,
      },   
      selectedCustomer: {},
      selectedOrder: {},
      purchaseTable: {
        headers: [
          {text: 'Order #', align: 'start', value: 'id'},
          { text: 'Date Created', value: 'createdAt' },
          { text: 'Grand Total', value: 'metadata.grandTotal' },
        ],
        items: []
      },
      adjuster: 0,
      signDialog: {
        isOpen: false,
        password: '',
        loading: false,
      },
    }
  },
  created(){
    this.debouncedUpdateReturn = _.debounce(this.updateReturn, 500)
  },
  async mounted(){
    try{
      this.initialLoader = true;
      let res = await axios.get(`${this.getEndpoint}/api/returns/${this.$route.params.id}`)
      if(res.data.error) throw res.data.error
      this.dreturn = res.data.data
      if(!this.dreturn.totalValue)
        this.dreturn.totalValue = this.getSubTotal
    
      if(!this.dreturn.addedToInventory)
        this.dreturn.addedToInventory = false

      if(this.dreturn.Customer){
        this.selectedCustomer = this.dreturn.Customer
        this.purchaseTable.items = this.dreturn.Customer.Orders
      }
      if(this.$route.query.order){
        res = await axios.get(`${this.getEndpoint}/api/orders/forReturns/${this.$route.query.order}`)
        if(res.data.error) throw res.data.error
        this.selectedOrder = res.data.data;
      }
      //return has its own scansense
      window.addEventListener('keypress', (e)=>{
        if(this.scanBus.locked && this.scanBus.type=="return"){
          this.cancelClearScanStringTimeout()
          this.clearScanStringTimeout()
          if(this.keyShortcutMode){
            this.cancelClearScanStringTimeout()
            if(e.key==='/'){
              this.endShortcut()
            }else{
              if(e.key==='Enter'){
                console.log(this.scanString)
                this.handleShortcut(this.scanString)
              }else{
                if(!this.scanString) this.scanString = ""
                this.scanString = this.scanString + e.key
              }
            }
          }else{
            if(e.key==='/'){
              if(!this.scanString){
                this.cancelClearScanStringTimeout()
                this.keyShortcutMode = true
              }else{
                this.scanString = this.scanString + e.key
              }
            }else{
              if(e.key==='Enter'){
                let validatedType = scanSystem.validateType(this.scanString)
                if(validatedType){
                  this.scanInProgress = true
                  this.handleScan(this.scanBus.type)
                  this.clearScanString
                  this.setScanBus('return')//important for this return specific thing
                }else{
                  this.clearScanString
                  this.resetScanBus()
                  this.setScanBus('return')
                }
              }else{
                this.scanInProgress = false
                if(!this.scanString) this.scanString = ""
                this.scanString = this.scanString + e.key
              }
            }
          }
        }
      })
    }
    catch (error) {
      console.error(error)
      this.snack(error.msg || error, "error");
    }
    finally {
      this.initialLoader = false;
      console.log("Done Mounting")
    }
  },
  computed: {
    ...mapGetters(['getId', 'getEndpoint', "isAllowed", 'isManaged', 'lookupUsername', 'scanBus']),
    modifyDisabledCriteria(){
      if(this.dreturn)
        return parseInt(this.dreturn.status) !== 0
      return 0
    },
    isSealable(){
      console.log(this.dreturn)
    return false
    },
    getSubTotal(){
      return this.dreturn&&this.dreturn.ReturnLineItems?this.dreturn.ReturnLineItems.reduce((acc,x)=>acc+parseFloat(x.returnValue),0):0
    },
  },
  methods: {
    ...mapMutations([
      'setScanBus',
      'resetScanBus',
    ]),
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    parseStatus(id){
      if(id===-1) return "Voided"
      else if(id===0) return "Draft"
      else if(id===1) return "Sealed"
    },
    resetSearchCustomerDialog(){
      this.searchCustomerDialog.term = ''
      this.searchCustomerDialog.results = []
      this.searchCustomerDialog.loader = false
    },
    openSearchCustomerDialog(){
      this.searchCustomerDialog.isOpen = true
    },
    closeSearchCustomerDialog(){
      this.resetSearchCustomerDialog()
      this.searchCustomerDialog.isOpen = false
    },
    async searchCustomer(){
      try {
        if(this.searchCustomerDialog.term){
          this.searchCustomerDialog.loader = true
          let res = await axios.get(`${this.getEndpoint}/api/customers/search?val=${encodeURIComponent(this.searchCustomerDialog.term)}`);
          if(res.data.error) throw res.data.error
          this.searchCustomerDialog.results = res.data.data
        }
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      }finally{
        this.searchCustomerDialog.loader = false
      }
    },
    async setCustomer(customer){
      try {
        if(this.dreturn.customerId) await this.removeCustomerFromReturn()
        let res = await axios.get(`${this.getEndpoint}/api/customers/withOrders/${customer.id}`)
        if(res.data.error) throw res.data.error
        this.dreturn.customerId = customer.id;
        this.selectedOrder = {};
        this.selectedCustomer = res.data.data
        this.purchaseTable.items = res.data.data.Orders
        await this.updateReturn()
        this.closeSearchCustomerDialog();
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      }
    },
    async removeCustomerFromReturn(){
      try {
        this.dreturn.customerId = null
        this.selectedCustomer = {};
        this.selectedOrder = {};
        this.dreturn.totalValue = 0
        let res = await axios.delete(`${this.getEndpoint}/api/returns/lineItems/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
        this.dreturn.ReturnLineItems = [];
        this.purchaseTable.items = []
        if(this.$route.query.order)
          await this.$router.replace({path: `/${this.pluralLower}/view/${this.$route.params.id}`});
        this.resetSearchCustomerDialog()
        await this.updateReturn()        
      } catch (error) {
        console.log(error)
      }

    },
    async updateReturn(){
      try {
        if(this.modifyDisabledCriteria) return // very important it must be at the top
        if(this.initialLoader) return false
        if(this.dreturn && this.dreturn.status > 0){
          throw "❌ Return already Sealed."
        }
        this.updateStateValue = 0
        this.dreturn.totalValue
        let res = await axios.put(`${this.getEndpoint}/api/returns/${this.$route.params.id}`, this.dreturn)
        console.log("Update Request sent out.")
        if(res.data.error) throw res.data.error
        this.updateStateValue = 1
        this.returnOk()
        // res
      } catch (error) {
        this.updateStateValue = 2
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.loader = false
      }
    },
    clearScanString(){
      this.scanString = ''
      this.scanInProgress = false
      console.log("🔁 Scan String Cleared")
    },
    clearScanStringTimeout(){
      this.timeout = setTimeout(this.clearScanString,500)
    },
    cancelClearScanStringTimeout(){
      clearTimeout(this.timeout)
    },
    async handleScan(type){
      try {
        let s = this.scanString.replace(/\\\/\/\\=/,'')
        let pId = (s.replace(`${type}-`, ''));
        let p;
        pId
        let prod = null
        switch(type){
          case 'INV':
            console.log("Return Invoice Scanned: ", s)
            this.handleScannedInvoice(s.split("-")[1])
            this.scanInProgress = false
            break
          case 'VSID':
            // this.setScanBus({username: this.lookupUsername(pId.split("-")[0]), uId: pId.split("-")[0]})
            // console.log(this.lookupUsername(pId.split("-")[0]))
            p = '/users/view/'+pId.split("-")[0]
            if(this.$route.path!==p)
              await this.$router.push({path: p})
            this.scanInProgress = false
            break
          case 'JT':
            console.log("Job Ticket Scanned: ", s)
            p = `/orders/view/${s.split('-')[1]}`
            if(this.$route.path!==p)
              await this.$router.push({path: p})
            this.scanInProgress = false
            break
          case 'DN':
            console.log("Delivery Note Scanned: ", s)
            p = `/deliveries/view/${s.split('-')[1]}`
            if(this.$route.path!==p)
              await this.$router.push({path: p})
            this.scanInProgress = false
            break
          case 'PL':
            console.log("Product Scanned: ", s) //this
            this.openProductScanDialog()
            prod = await this.lookupProduct((s.split('-')[1]), "ID")
            if(!prod) throw "Product Not In VIMS DB."
            this.productScanDialog.item = prod.data
            this.scanInProgress = false
            break
          case 'EXT':
            console.log("Invoice External Product Scanned: ", s)
            this.openProductScanDialog()
            prod = await this.lookupProduct(s, "SKU")
            if(!prod) throw "External Product Not In VIMS DB."
            this.productScanDialog.item = prod
            this.scanInProgress = false
            break
        }
      } catch (error) {
        console.log(error)
        this.scanInProgress = false
        this.snack(error)
      }
    },
    openProductScanDialog(){
      this.productScanDialog.isOpen = true
      this.productScanDialog.loader = true
    },
    closeProductScanDialog(){
      this.productScanDialog.isOpen = false,
      this.productScanDialog.loader = false
      this.productScanDialog.item = ''
    },
    async lookupProduct(s, type){
      try {
        let res;
        if(type === "SKU")
          res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
        if(type === "PL")
          res = await axios.get(`${this.getEndpoint}/api/products/${s}`)
        if(res.data.error) throw res.data.error
        if(!res.data.data) throw 'External barcode is not in DB.'
        return res.data.data
      } catch (error) {
        if(error.customError){
          console.error(error)
          this.snack('External barcode is not in DB.')
        }
        else this.snack(error)
      } finally {
        this.productScanDialog.loader = false
      }
    },
    async handleScannedInvoice(id){
      try {
        let res = await axios.get(`${this.getEndpoint}/api/orders/forReturns/${id}`)
        if(res.data.error) throw res.data.error
        let data = res.data.data
        if(!data) throw "Invoice needs to be sealed."
        if(this.dreturn.customerId){
           if(data.Customer.id !== this.dreturn.customerId) throw "Invoice does not belong to the selected customer."
        }else{
          this.setCustomer(data.Customer)
        }
        this.loadInvoice(id, data)
      } catch (error) {
        console.log(error)
        this.snack(error)
      }
    },
    purchaseTableRowClick(row){
      this.loadInvoice(row.id)
    },
    async loadInvoice(id, order){
      try {
        if(!(this.$route.query.order && this.$route.query.order==id)) {
          window.scrollTo({top: 0});
          await this.$router.replace({path: `/${this.pluralLower}/view/${this.$route.params.id}`, query: {order: id}});
          if(order){
          this.selectedOrder = order;
          }else{
            let res = await axios.get(`${this.getEndpoint}/api/orders/forReturns/${id}`)
            if(res.data.error) throw res.data.error
            this.selectedOrder = res.data.data;
          }
          this.returnOk()
        } 
      } catch (error) {
        console.log(error)
        this.snack(error)
      }
    },
    async removeReturnLineItem(e){
      try {
        let res = await axios.delete(`${this.getEndpoint}/api/returns/lineItem/${e}`)
        if(res.data.error) throw res.data.error
        let i = (this.dreturn.ReturnLineItems.findIndex(x=>x.id===e))
        if(i == -1) throw "Item Could Not Be Found. Refresh this page."
        this.dreturn.ReturnLineItems.splice(i,1)
        if(!this.dreturn.ReturnLineItems) this.dreturn.ReturnLineItems = []
        this.returnOk()
      } catch (error) {
        console.log(error)
        this.snack(error)
      }
    },
    async addReturnLineItem(p){
      try {
        this.updateStateValue = 0
        let res = await axios.get(`${this.getEndpoint}/api/returns/addItemPrecheck/${this.dreturn.customerId}/${p.productId}`)
        if(!res.data) throw "Could not add item."
        console.log("Precheck done")
        let tempLineItem = {
          returnId: this.$route.params.id,
          productId: p.productId,
          orderId: p.orderId,
          serial: '',
          returnValue: p.unitPrice,
          isReplacement: false,
          notes: '',
          metadata: null,
          Product: {
            name: p.productName
          }
        }
        res = await axios.post(`${this.getEndpoint}/api/returns/lineItem/${this.dreturn.id}`, tempLineItem)
        if(res.data.error) throw res.data.error
        this.dreturn?.ReturnLineItems.push(res.data.data)
        this.updateStateValue = 1
        this.returnOk()
      } catch (error) {
        console.log(error)
        this.snack(error)
        this.updateStateValue = 2
      }
    },
    async updateReturnLineItem(p){
      try {
        this.updateStateValue = 0
        let res = await axios.put(`${this.getEndpoint}/api/returns/lineItem/${p.id}`, p)
        if(res.data.error) throw res.data.error
        console.log(res.data.data)
        let ix = this.dreturn.ReturnLineItems.findIndex(x=>x.id==p.id)
        ix==-1?null:this.dreturn.ReturnLineItems.splice(ix, 1, res.data.data)
        this.updateStateValue = 1
        this.returnOk()
      } catch (error) {
        console.log(error)
        this.snack(error)
        this.updateStateValue = 2
      }
    },
    openSignDialog(){
      this.signDialog.isOpen = true
      this.signDialog.loading = false
      this.signDialog.password = ''
    },
    closeSignDialog(){
      this.signDialog.isOpen = false
    },
    async attemptSign(){
      try {
        this.signDialog.loading = true
        let res = await axios.put(`${this.getEndpoint}/api/returns/sign/${this.dreturn.id}`, {password: this.signDialog.password, return: this.dreturn})
        if(res.data.error) throw res.data.error
        this.snack(`✅ Return signed. Created CN # ${res.data.data} for this return.`)
        this.closeSignDialog()
        this.dreturn.status = 1
      } catch (error) {
        console.log(error)
        this.snack(error.msg)
      }finally{
        this.signDialog.loading = false
        this.signDialog.password = ''
      }
    },
    returnOk(){
      if(this.dreturn?.status == 0){ // return is a draft
        console.log(this.dreturn)
        if(!this.dreturn.customerId){
          console.log("!this.dreturn.customerId")
          return false
        }
        if(!this.dreturn.totalValue){
          console.log("!this.dreturn.totalValue")
          return false
        }
        if(!this.dreturn.ReturnLineItems){
          console.log("!this.dreturn.ReturnLineItems")
          return false
        }
        if(this.dreturn.ReturnLineItems.length < 1){
          console.log("this.dreturn.ReturnLineItems.length < 1")
          return false
        }
        console.log("Return ok final.")
        return true
      }
      console.log("Return not ok final.")
      return false
    },
  }
}
</script>
